import React, { useState } from "react";
import Context from "./index";
import { create, getAll, remove } from "../../Api/AuthApi";
import { message, Form } from "antd";
const AdminAnalyticsProvider = ({ children, ...props }) => {
  const [adAnalyticsListFilter, setAdAnalyticsListFilter] = useState({
    type: "this_month",
    from_date: "",
    to_date: "",
  });
  const [anTableFilter, setAnTableFilter] = useState({
    page: 1,
    limit: 10,
  });
  const [adOrderStatusFilter, setAdOrderStatusFilter] = useState({
    status_type: "",
  });
  const [anOneLoader, setAnOneLoader] = useState(false);
  const [anTwoLoader, setAnTwoLoader] = useState(false);
  const [anThreeLoader, setAnThreeLoader] = useState(false);
  const [anFourLoader, setAnFourLoader] = useState(false);
  const [anFiveLoader, setAnFiveLoader] = useState(false);
  const [anSixLoader, setAnSixLoader] = useState(false);
  const [anSevenLoader, setAnSevenLoader] = useState(false);
  const [anEightLoader, setAnEightLoader] = useState(false);
  const [anNineLoader, setAnNineLoader] = useState(false);
  const [anNineDownloadLoader, setAnNineDownloadLoader] = useState(false);
  const [anTenLoader, setAnTenLoader] = useState(false);
  const [anTenDownLoadLoader, setAnTenDownLoadLoader] = useState(false);
  const [anLevenLoader, setAnLevenLoader] = useState(false);
  const [anLevenDownLoadLoader, setAnLevenDownLoadLoader] = useState(false);
  const [anOneData, setAnOneData] = useState({});
  const [anTwoData, setAnTwoData] = useState([]);
  const [anThreeData, setAnThreeData] = useState([]);
  const [anFourData, setAnFourData] = useState([]);
  const [anFiveData, setAnFiveData] = useState([]);
  const [anSixData, setAnSixData] = useState([]);
  const [anSevenData, setAnSevenData] = useState([]);
  const [anEightData, setAnEightData] = useState([]);
  const [anNineData, setAnNineData] = useState([]);
  const [anTenData, setAnTenData] = useState([]);
  const [anLevenData, setAnLevenData] = useState([]);
  const [anOrderStatus, setAnOrderStatus] = useState([
      {
        label: "Filter By Status",
        value: "",
      },
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Packed",
        value: "packed",
      },
      {
        label: "Dispatched",
        value: "dispatched",
      },
      {
        label: "Shipped",
        value: "shipped",
      },
      {
        label: "Delivered",
        value: "delivered",
      },

    ])
  const [anSelect, setAnSelect] = useState([
    {
      label: "This Month",
      value: "this_month",
    },
    {
      label: "Last Month",
      value: "last_month",
    },
    {
      label: "This Year",
      value: "this_year",
    },
    {
      label: "Last Year",
      value: "last_year",
    },
    {
      label: "Custom",
      value: "custom",
    },
    {
      label: "Today",
      value: "today",
    },
    {
      label: "Yesterday",
      value: "yesterday",
    },
    {
      label: "This Week",
      value: "this_week",
    },
    {
      label: "Last Week",
      value: "last_week",
    },
    {
      label: "This Quarter 1",
      value: "this_quarter_1",
    },
    {
      label: "This Quarter 2",
      value: "this_quarter_2",
    },
    {
      label: "This Quarter 3",
      value: "this_quarter_3",
    },
    {
      label: "This Quarter 4",
      value: "this_quarter_4",
    },
    {
      label: "Last Quarter 1",
      value: "last_quarter_1",
    },
    {
      label: "Last Quarter 2",
      value: "last_quarter_2",
    },
    {
      label: "Last Quarter 3",
      value: "last_quarter_3",
    },
    {
      label: "Last Quarter 4",
      value: "last_quarter_4",
    },
    {
      label: "This Year Half 1",
      value: "this_year_half_1",
    },
    {
      label: "This Year Half 2",
      value: "this_year_half_2",
    },
    {
      label: "Last Year Half 1",
      value: "last_year_half_1",
    },
    {
      label: "Last Year Half 2",
      value: "last_year_half_2",
    },
  ]);
  async function anOneFetch() {
    setAnOneLoader(true);
    await getAll(`anoverview.php?action=totaloverview`, adAnalyticsListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnOneData(res?.data);
          setAnOneLoader(false);
        } else {
          setAnOneLoader(false);
        }
      })
      .catch((err) => {
        setAnOneLoader(false);
      });
  }
  async function anTwoFetch() {
    setAnTwoLoader(true);
    await getAll(`anoverview.php?action=salesoverview`, adAnalyticsListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnTwoData(res?.data);
          setAnTwoLoader(false);
        } else {
          setAnTwoLoader(false);
        }
      })
      .catch((err) => {
        setAnTwoLoader(false);
      });
  }
  async function anThreeFetch() {
    setAnThreeLoader(true);
    await getAll(`anoverview.php?action=ordersoverview`, adAnalyticsListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnThreeData(res?.data);
          setAnThreeLoader(false);
        } else {
          setAnThreeLoader(false);
        }
      })
      .catch((err) => {
        setAnThreeLoader(false);
      });
  }
  async function anFourFetch() {
    setAnFourLoader(true);
    await getAll(`anoverview.php?action=soldoverview`, adAnalyticsListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnFourData(res?.data);
          setAnFourLoader(false);
        } else {
          setAnFourLoader(false);
        }
      })
      .catch((err) => {
        setAnFourLoader(false);
      });
  }
  async function anFiveFetch() {
    setAnFiveLoader(true);
    await getAll(
      `anoverview.php?action=customeroverview`,
      adAnalyticsListFilter
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnFiveData(res?.data);
          setAnFiveLoader(false);
        } else {
          setAnFiveLoader(false);
        }
      })
      .catch((err) => {
        setAnFiveLoader(false);
      });
  }
  async function anSixFetch() {
    setAnSixLoader(true);
    await getAll(
      `anoverview.php?action=customertopspend`,
      adAnalyticsListFilter
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnSixData(res?.data);
          setAnSixLoader(false);
        } else {
          setAnSixLoader(false);
        }
      })
      .catch((err) => {
        setAnSixLoader(false);
      });
  }
  async function anSevenFetch() {
    setAnSevenLoader(true);
    await getAll(`anoverview.php?action=producttopsold`, adAnalyticsListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnSevenData(res?.data);
          setAnSevenLoader(false);
        } else {
          setAnSevenLoader(false);
        }
      })
      .catch((err) => {
        setAnSevenLoader(false);
      });
  }
  async function anEightFetch() {
    setAnEightLoader(true);
    await getAll(`anoverview.php?action=categorytopsold`, adAnalyticsListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnEightData(res?.data);
          setAnEightLoader(false);
        } else {
          setAnEightLoader(false);
        }
      })
      .catch((err) => {
        setAnEightLoader(false);
      });
  }
  async function anNineFetch() {
    setAnNineLoader(true);
    await getAll(`anproducts.php?action=producttopsold`, {
      ...adAnalyticsListFilter,
      ...anTableFilter,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnNineData(res?.data);
          setAnNineLoader(false);
        } else {
          setAnNineLoader(false);
        }
      })
      .catch((err) => {
        setAnNineLoader(false);
      });
  }
  async function anTenFetch() {
    setAnTenLoader(true);

    await getAll(`anorders.php?action=orderssold`, {
      ...adAnalyticsListFilter,
      ...anTableFilter,
      ...adOrderStatusFilter,

    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnTenData(res?.data);
          setAnTenLoader(false);
        } else {
          setAnTenLoader(false);
        }
      })
      .catch((err) => {
        setAnTenLoader(false);
      });
  }

  async function anLevenFetch() {
    setAnLevenLoader(true);
    await getAll(`ancustomers.php?action=customers`, {
      ...adAnalyticsListFilter,
      ...anTableFilter,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAnLevenData(res?.data);
          setAnLevenLoader(false);
        } else {
          setAnLevenLoader(false);
        }
      })
      .catch((err) => {
        setAnLevenLoader(false);
      });
  }


  async function jsonToCsv(headerMapping = [], data = [], fileName = "") {
    const csvRows = [];
    const headers = Object.keys(headerMapping).map((key) => headerMapping[key]);
    csvRows.push(headers.join(","));
    data.forEach((item) => {
      const values = Object.keys(headerMapping).map((key) =>
        JSON.stringify(item[key] || "")
      );
      csvRows.push(values.join(","));
    });
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function anNineDownLoad() {
    setAnNineDownloadLoader(true);
    const data = await getAll(`anproducts.php?action=producttopsold`, {
      ...adAnalyticsListFilter,
      ...{ page: 1, limit: anNineData?.pagination?.total_count || 10000 },
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          return res?.data?.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        return [];
      })
      .finally(() => {
        setAnNineDownloadLoader(false);
      });
    return data;
  }

  async function anTenDownload() {
    setAnTenDownLoadLoader(true);
    const data = await getAll(`anorders.php?action=orderssold`, {
      ...adAnalyticsListFilter,
      ...{ page: 1, limit: anTenData?.pagination?.total_count || 10000 },
      ...adOrderStatusFilter,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          return res?.data?.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        return [];
      })
      .finally(() => {
        setAnTenDownLoadLoader(false);
      });
    return data;
  }

  async function anLevenDownload() {
    setAnLevenDownLoadLoader(true);
    const data = await getAll(`ancustomers.php?action=customers`, {
      ...adAnalyticsListFilter,
      ...{ page: 1, limit: anLevenData?.pagination?.total_count || 10000 },
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          return res?.data?.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        return [];
      })
      .finally(() => {
        setAnLevenDownLoadLoader(false);
      });
    return data;
  }

  return (
    <Context.Provider
      value={{
        ...props,
        adAnalyticsListFilter,
        setAdAnalyticsListFilter,
        adOrderStatusFilter,
        setAdOrderStatusFilter,
        anOneData,
        anOneLoader,
        anTwoData,
        anTwoLoader,
        anThreeData,
        anThreeLoader,
        anFourData,
        anFourLoader,
        anFiveData,
        anFiveLoader,
        anSixData,
        anSixLoader,
        anSevenData,
        anSevenLoader,
        anEightData,
        anEightLoader,
        anOneFetch,
        anTwoFetch,
        anThreeFetch,
        anFourFetch,
        anFiveFetch,
        anSixFetch,
        anSevenFetch,
        anEightFetch,
        anSelect,
        setAnSelect,
        anOrderStatus,
        setAnOrderStatus,
        anTableFilter,
        setAnTableFilter,
        anNineFetch,
        anNineData,
        setAnNineData,
        anNineLoader,
        setAnNineLoader,
        anTenFetch,
        anTenData,
        setAnTenData,
        anTenLoader,
        setAnTenLoader,
        anLevenLoader,
        setAnLevenLoader,
        anLevenData,
        setAnLevenData,
        anLevenFetch,
        jsonToCsv,
        anNineDownloadLoader,
        setAnNineDownloadLoader,
        anNineDownLoad,
        anTenDownLoadLoader,
        setAnTenDownLoadLoader,
        anTenDownload,
        anLevenDownLoadLoader,
        setAnLevenDownLoadLoader,
        anLevenDownload,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AdminAnalyticsProvider;